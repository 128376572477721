import TiltPlane from "@cospex/client/components/TiltPane";
import useTranslation from "@cospex/client/hooks/useTranslation";
import HeroImageA from "@cospex/client/parcel/img/hero_image_a.svg";
import HeroImageB from "@cospex/client/parcel/img/hero_image_b.svg";
import HeroImageC from "@cospex/client/parcel/img/hero_image_c.svg";
import { Box } from "@mui/material";

export default function HomepageGraphic() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        aspectRatio: "1/1",
        maxWidth: 550,
        position: "relative",
      }}
    >
      <TiltPlane>
        <Box
          component="img"
          src={HeroImageA}
          alt="Hero Image"
          sx={{ width: "100%", height: "100%" }}
        />
        <Box
          component="img"
          src={HeroImageB}
          alt="Hero Image"
          sx={{ width: "100%", height: "100%" }}
        />
        <Box
          component="img"
          src={HeroImageC}
          alt="Hero Image"
          sx={{ width: "100%", height: "100%" }}
        />

        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              left: "36%",
              top: "82.2%",
              color: "black",
              fontWeight: 400,
              fontFamily: "sans-serif",
              fontSize: {
                xs: 12,
                md: 14,
              },
            }}
          >
            {t("homepage-graphic-message")}
          </Box>
        </Box>
      </TiltPlane>
    </Box>
  );
}
